// import React from 'react';
// import { Box, Typography, Card, CardContent, CssBaseline, ThemeProvider, createTheme, Grid } from '@mui/material';
// import boyImage from './assets/fiu1.jpg';
// import girlImage from './assets/girlWithBall.png';

// // Create a theme with custom font (Roboto)
// const theme = createTheme({
//     typography: {
//         fontFamily: '"Roboto", sans-serif',
//     },
// });

// const DiagnosztikaPage = () => {
//     return (
//         <ThemeProvider theme={theme}>
//             <CssBaseline /> {/* Apply global styles */}
//             <Box
//                 sx={{
//                     maxWidth: '1200px',
//                     margin: '0 auto',
//                     padding: '20px',
//                     backgroundColor: 'rgba(255, 255, 255, 0.9)',
//                     borderRadius: '8px',
//                 }}
//             >
//                 <Box
//                     sx={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         marginBottom: '20px',
//                         justifyContent: 'center', // Center the content horizontally
//                         flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile and align horizontally on larger screens
//                     }}
//                 >
//                     {/* Title on the left */}
//                     <Typography
//                         variant="h4"
//                         sx={{
//                             textAlign: 'left',
//                             color: '#000',
//                             fontWeight: 'bold',
//                             marginRight: { sm: '30px', xs: '0' }, // Space between title and image on larger screens
//                         }}
//                     >
//                         Diagnosztikai Eljárások
//                     </Typography>

//                     {/* Boy Image on the right */}
//                     <Box
//                         sx={{
//                             width: '160px', // Adjust size to make it bigger
//                             height: 'auto',
//                             borderRadius: '8px',
//                             overflow: 'hidden',
//                             marginLeft: { sm: '30px', xs: '0' }, // Space between image and title on larger screens
//                             marginBottom: { xs: '20px', sm: '0' }, // Space between image and title on mobile
//                         }}
//                     >
//                         <img src={boyImage} alt="Boy" style={{ width: '100%', height: 'auto' }} />
//                     </Box>
//                 </Box>

//                 <Card sx={{ marginBottom: '20px', boxShadow: 3 }}>
//                     <CardContent>
//                         <Typography
//                             variant="body1"
//                             sx={{
//                                 marginBottom: '20px',
//                                 fontSize: '18px',
//                                 color: '#333',
//                             }}
//                         >
//                             A felmérések szülő jelenlétében zajlanak. Minden esetben anamnézis felvétel történik, és a szülőknek van lehetőségük a felmerült
//                             problémák megbeszélésére. A terapeuták a saját kompetenciáik alapján tudnak segítséget adni, illetve amennyiben szükséges a családot
//                             más szakemberhez, intézményhez irányítani. Kérjük, hogy a gyermekek számára tornacipőt hozzanak!
//                         </Typography>
//                     </CardContent>
//                 </Card>

//                 <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
//                     {/* Longitudinális Komplex Vizsgálat Card */}
//                     <Grid item xs={12} md={6}>
//                         <Card sx={{ boxShadow: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
//                             <CardContent sx={{ flex: 1 }}>
//                                 <Typography
//                                     variant="h5"
//                                     sx={{
//                                         marginBottom: '10px',
//                                         fontWeight: 'bold',
//                                         color: '#000',
//                                     }}
//                                 >
//                                     Longitudinális Komplex Vizsgálat
//                                 </Typography>
//                                 <Typography>LongiKid<sup>®</sup></Typography>
//                                 <Typography variant="body1" sx={{ color: '#555' }}>
//                                     Méri a mozgásos, kognitív, nyelvi teljesítményt, valamint az idegrendszer érettségét, saját kiértékelő és eszköz rendszer
//                                     segítségével. A vizsgálat 3 hónapos kortól 11 éves korig végezhető el, az adott korosztálynak megfelelően összeállított
//                                     feladatokkal és eszközökkel. Kb 60 – 120 percet vesz igénybe, korosztálytól függően. A Longitudinális Komplex Vizsgálat
//                                     elengedhetetlen része annak, hogy a gyermek TSMT<sup>®</sup> terápiájának a bementi szintjét meghatározzuk, ezért felvétele javasolt,
//                                     amennyiben a gyermek több területen is mutatja az eltérő fejlődés tüneteit, vagy a szülők/szakemberek indokoltnak tartják
//                                     a terápia megkezdését valamely diagnózis vagy tünetegyüttes miatt. A vizsgálat a szülő jelenlétében zajlik.
//                                 </Typography>
//                             </CardContent>
//                         </Card>
//                     </Grid>

//                     {/* Állapot és Mozgásvizsgálat Card */}
//                     <Grid item xs={12} md={6}>
//                         <Card sx={{ boxShadow: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
//                             <CardContent sx={{ flex: 1 }}>
//                                 <Typography
//                                     variant="h5"
//                                     sx={{
//                                         marginBottom: '10px',
//                                         fontWeight: 'bold',
//                                         color: '#000',
//                                     }}
//                                 >
//                                     Állapot- és Mozgásvizsgálat
//                                 </Typography>
//                                 <Typography variant="body1" sx={{ color: '#555' }}>
//                                     Egy komplex vizsgálat az 5 éven felüli gyermekek számára, amely 5 részterületen méri a gyermek idegrendszerének aktuális
//                                     állapotát. Célja: az idegrendszer érettségi szintjének meghatározása, illetve az éretlen működés területeinek feltérképezése,
//                                     az iskolai beválás rizikóinak előrejelzésére. A vizsgálat a szülő jelenlétében zajlik, kb 60 percet vesz igénybe. Az eredmények
//                                     ismeretében meghatározható a fejlesztés formája, célja (egyéni vagy csoportos).
//                                 </Typography>
//                                 <Typography variant="body1" sx={{ color: '#555' }}>
//                                     A felmérések szülő jelenlétében zajlanak. Minden esetben anamnézis felvétel történik, és a szülőknek van lehetőségük a felmerült
//                                     problémák megbeszélésére. A terapeuták a saját kompetenciáik alapján tudnak segítséget adni, illetve amennyiben szükséges
//                                     a családot más szakemberhez, intézményhez irányítani. Kérjük, hogy a gyermekek számára tornacipőt hozzanak!
//                                 </Typography>
//                             </CardContent>
//                         </Card>
//                     </Grid>
//                 </Grid>
//             </Box>
//         </ThemeProvider>
//     );
// };

// export default DiagnosztikaPage;

import React from 'react'; 
import { Box, Typography, Card, CardContent, CssBaseline, ThemeProvider, createTheme, Grid } from '@mui/material';
import boyImage from './assets/fiu1.jpg';
import girlImage from './assets/girlWithBall.png';

// Create a theme with custom font (Roboto)
const theme = createTheme({
    typography: {
        fontFamily: '"Roboto", sans-serif',
    },
});

const DiagnosztikaPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline /> {/* Apply global styles */}
            <Box
                sx={{
                    maxWidth: '1200px',
                    margin: '0 auto',
                    padding: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '8px',
                }}
            >
                {/* Title and images side by side */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', // Center the content horizontally
                        flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile and align horizontally on larger screens
                        marginBottom: '20px',
                    }}
                >
                    {/* Girl Image on the left */}
                    <Box
                        sx={{
                            width: '160px', // Adjust size as needed
                            height: 'auto',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            marginRight: { sm: '30px', xs: '0' }, // Space between girl image and title on larger screens
                            marginBottom: { xs: '20px', sm: '0' }, // Space between girl image and title on mobile
                        }}
                    >
                        <img src={girlImage} alt="Girl" style={{ width: '100%', height: 'auto' }} />
                    </Box>

                    {/* Title in the middle */}
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: 'center',
                            color: '#000',
                            fontWeight: 'bold',
                            marginRight: { sm: '30px', xs: '0' }, // Space between title and boy image
                            marginLeft: { sm: '30px', xs: '0' }, // Space between title and girl image
                        }}
                    >
                        Diagnosztikai Eljárások
                    </Typography>

                    {/* Boy Image on the right */}
                    <Box
                        sx={{
                            width: '160px', // Adjust size as needed
                            height: 'auto',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            marginLeft: { sm: '30px', xs: '0' }, // Space between boy image and title on larger screens
                            marginBottom: { xs: '20px', sm: '0' }, // Space between boy image and title on mobile
                        }}
                    >
                        <img src={boyImage} alt="Boy" style={{ width: '100%', height: 'auto' }} />
                    </Box>
                </Box>

                {/* Card Content Below */}
                <Card sx={{ marginBottom: '20px', boxShadow: 3 }}>
                    <CardContent>
                        <Typography
                            variant="body1"
                            sx={{
                                marginBottom: '20px',
                                fontSize: '18px',
                                color: '#333',
                            }}
                        >
                            A felmérések szülő jelenlétében zajlanak. Minden esetben anamnézis felvétel történik, és a szülőknek van lehetőségük a felmerült
                            problémák megbeszélésére. A terapeuták a saját kompetenciáik alapján tudnak segítséget adni, illetve amennyiben szükséges a családot
                            más szakemberhez, intézményhez irányítani. Kérjük, hogy a gyermekek számára tornacipőt hozzanak!
                        </Typography>
                    </CardContent>
                </Card>

                <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
                    {/* Longitudinális Komplex Vizsgálat Card */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent sx={{ flex: 1 }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: '10px',
                                        fontWeight: 'bold',
                                        color: '#000',
                                    }}
                                >
                                    Longitudinális Komplex Vizsgálat
                                </Typography>
                                <Typography>LongiKid<sup>®</sup></Typography>
                                <Typography variant="body1" sx={{ color: '#555' }}>
                                    Méri a mozgásos, kognitív, nyelvi teljesítményt, valamint az idegrendszer érettségét, saját kiértékelő és eszköz rendszer
                                    segítségével. A vizsgálat 3 hónapos kortól 11 éves korig végezhető el, az adott korosztálynak megfelelően összeállított
                                    feladatokkal és eszközökkel. Kb 60 – 120 percet vesz igénybe, korosztálytól függően. A Longitudinális Komplex Vizsgálat
                                    elengedhetetlen része annak, hogy a gyermek TSMT<sup>®</sup> terápiájának a bementi szintjét meghatározzuk, ezért felvétele javasolt,
                                    amennyiben a gyermek több területen is mutatja az eltérő fejlődés tüneteit, vagy a szülők/szakemberek indokoltnak tartják
                                    a terápia megkezdését valamely diagnózis vagy tünetegyüttes miatt. A vizsgálat a szülő jelenlétében zajlik.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Állapot és Mozgásvizsgálat Card */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent sx={{ flex: 1 }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: '10px',
                                        fontWeight: 'bold',
                                        color: '#000',
                                    }}
                                >
                                    Állapot- és Mozgásvizsgálat
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#555' }}>
                                    Egy komplex vizsgálat az 5 éven felüli gyermekek számára, amely 5 részterületen méri a gyermek idegrendszerének aktuális
                                    állapotát. Célja: az idegrendszer érettségi szintjének meghatározása, illetve az éretlen működés területeinek feltérképezése,
                                    az iskolai beválás rizikóinak előrejelzésére. A vizsgálat a szülő jelenlétében zajlik, kb 60 percet vesz igénybe. Az eredmények
                                    ismeretében meghatározható a fejlesztés formája, célja (egyéni vagy csoportos).
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#555' }}>
                                    A felmérések szülő jelenlétében zajlanak. Minden esetben anamnézis felvétel történik, és a szülőknek van lehetőségük a felmerült
                                    problémák megbeszélésére. A terapeuták a saját kompetenciáik alapján tudnak segítséget adni, illetve amennyiben szükséges
                                    a családot más szakemberhez, intézményhez irányítani. Kérjük, hogy a gyermekek számára tornacipőt hozzanak!
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default DiagnosztikaPage;

