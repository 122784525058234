import React from 'react';
import { Box, Typography, Grid, IconButton, Card, CardContent } from '@mui/material';
import { Phone, Email, LocationOn, Facebook } from '@mui/icons-material';

function KapcsolatPage() {
  return (
    <Box sx={{ padding: 4 }}>
      <Card sx={{ padding: 3, marginBottom: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
            Orosz Irén
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            KockaKuckó, Mozgás- és képességfejlesztés gyerekeknek
          </Typography>

          <Grid container spacing={2}>
            {/* Facebook */}
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', wordWrap: 'break-word', width: '100%' }}>
                <IconButton sx={{ color: '#1976d2' }}>
                  <Facebook />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    fontSize: { xs: '0.9rem', sm: '1rem' }, // Adjust font size for small screens
                  }}
                >
                  <a
                    href="https://www.facebook.com/kockakuckopecel"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    Facebook
                  </a>
                </Typography>
              </Box>
            </Grid>

            {/* Phone Number */}
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', wordWrap: 'break-word', width: '100%' }}>
                <IconButton sx={{ color: '#1976d2' }}>
                  <Phone />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    fontSize: { xs: '0.9rem', sm: '1rem' }, // Adjust font size for small screens
                  }}
                >
                  <a href="tel:+36203111441" style={{ textDecoration: 'none', color: 'inherit' }}>
                    +36203111441
                  </a>
                </Typography>
              </Box>
            </Grid>

            {/* Email */}
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', wordWrap: 'break-word', width: '100%' }}>
                <IconButton sx={{ color: '#1976d2' }}>
                  <Email />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    fontSize: { xs: '0.9rem', sm: '1rem' }, // Adjust font size for small screens
                  }}
                >
                  <a href="mailto:orosziren2@gmail.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                    orosziren2@gmail.com
                  </a>
                </Typography>
              </Box>
            </Grid>

            {/* Location */}
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', wordWrap: 'break-word', width: '100%' }}>
                <IconButton sx={{ color: '#1976d2' }}>
                  <LocationOn />
                </IconButton>
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    fontSize: { xs: '0.9rem', sm: '1rem' }, // Adjust font size for small screens
                  }}
                >
                  <a
                    href="https://www.google.com/maps?q=2119,+Pécel,+József+Attila+utca+28"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    2119, Pécel, József Attila utca 28.
                  </a>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default KapcsolatPage;
