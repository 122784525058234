
import React, { useState } from 'react';
import { Box, Grid, Typography, Avatar, Link, IconButton, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess  } from '@mui/icons-material'; // Arrow icon for collapse functionality
import oroszIren from './assets/oroszIren.jpg'; // Add image for Orosz Irén
import bozoAlexandra from './assets/bozoAlexandra.jpg'; // Add image for Bozsó Alexandra
import sapzskyAnita from './assets/sapzskyAnita.jpg'; // Add image for Sápszky Anita
import balajtiBetti from './assets/balajtiBetti.png'; // Add image for Sápszky Anita

const SzakemberekPage = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const experts = [
        {
            name: 'Orosz Irén',
            description: <>Orosz Irén vagyok, óvodapedagógus, mozgásfejlesztő szakember. Óvodapedagógiával kapcsolatos tanulmányaimat az ELTE Tanító és Óvóképző Karán, a szarvasi Tessedik Sámuel Főiskolán végeztem, Sokféle továbbképzésen vettem részt, elsősorban a fejlesztő pedagógiával, illetve mozgás és képességfejlesztéssel kapcsolatos témakörben.

                A TSMT<sup>®</sup> módszert 2012 -ben tanultam meg a BHRG Alapítvány központjában, azóta végzek ilyen jellegű fejlesztést a gyermekek körében. Elsősorban a 1,5 - 10 éves korosztállyal foglalkozom, egyéni illetve csoportos formában. Úgy gondolom, hogy az a gyermek, aki valamilyen TSMT<sup>®</sup> foglalkozáson vesz részt, lehetőséget kap arra, hogy saját képességeihez, adottságaihoz képest a lehető legjobb teljesítményt tudja elérni az iskolában, sportban, vagy az élet más területein. Hiszem, hogy a TSMT<sup>®</sup> egy lehetséges út a gyermek fejlődéséhez, és az egyik legjobb, és leghasznosabb út.

                2013-ban létrehoztuk Pécelen a Kockakuckó Mozgás és Képességfejlesztő Központot, ahol egyéni és csoportos formában történik a mozgásfejlesztés.

                Szeretettel várom a hozzám forduló gyerekeket és szüleiket.

            </>,
            phone: '06/20-3111441',
            image: oroszIren,
        },
        {
            name: 'Bozsó Alexandra',
            description: <>2016 óta óvodapedagógusként dolgoztam a XVII. kerületben. Alapdiplomám megszerzését követően elvégeztem a fejlesztőpedagógusi képzést is, illetve egyéni- és csoportos mozgásterapeuta képzéseket.

                3 hónapos kortól – 11 éves korig egyéni mozgásterápiákat tartok.
                A nagyobb korosztály számára a mozgásterápia csoportos foglalkozások keretében is megvalósulhat.
                4-8 éves gyermekek számára tartok egyéni képességfejlesztő foglalkozásokat, melyek célja a különböző részképességekben való lemaradások megsegítése.
                A terápiák, fejlesztőfoglalkozások megkezdése előtt minden esetben egy komplex vizsgálat történik, mely részletes képet ad a gyermekről, ezáltal segítve az eredményes munkát.
                1,5-3 éves korosztálynak játékos zenés, illetve mozgásos foglalkozásokat szervezek, amelyeken a szülőkkel együtt vehetnek részt a gyerekek.</>,
            phone: '06/30-5695459',
            image: bozoAlexandra,
        },
        {
            name: 'Sápszky Anita',
            description: (
                <>
                    Sápszky Anita vagyok LongiKid<sup>®</sup> vizsgáló, csoportos mozgásfejlesztő terapeuta. A TSMT<sup>®</sup> módszert a BHRG Alapítvány TSMT<sup>®</sup> Fejlesztő Központjában tanultam. 2010-ben diplomáztam a Liszt Ferenc Zeneművészeti Egyetem Tanárképző Intézetében. 2012 óta a Czövek Erna Alapfokú Művészetoktatási Intézmény énektanára vagyok. Csoportos terapeutaként elsősorban a nagyobb korosztálynak, 3-11 éves korig tartok TSMT<sup>®</sup> tornát.
                    A LongiKid<sup>®</sup> egy olyan neuro-szenzomotoros eljárás, amely alkalmas a gyermekek mozgásos és kognitív, figyelmi fejlődésprofiljának jellemzésére 3 hónapos kortól 11 éves korig. A vizsgálattal azonosíthatóak az eltérően fejlődő funkciók és az idegrendszeri érési zavarok.

                    Célom, hogy minden gyermek megkapja a neki járó segítséget, hogy egyenlő feltételekkel kezdhesse meg az iskolát, vagy ha már iskolás, sikeresen és könnyedén vegye az akadályokat.                </>
            ),
            phone: '06/70-5769282',
            image: sapzskyAnita,
        },
        {
            name: 'Balajti Bernadett',
            description:
                <>
                    Közel tizenöt éve dolgozom óvodapedagógusként. Integrációs és innovatív szemléletmódon keresztül, saját csoporton belül, 7éves tapasztalatot szereztem az autizmus spektrumzavarral és az ADHD-val, BTMN-el küzdő gyerekekkel is.

                    Külön büszkeséggel tölt el, hogy 2022.-ben Bp.Főv. XIII. Kerületi Önkormányzat Egyesített Óvoda, 18 tagóvodája közül, én kaphattam „Az év integrációs pedagógusa” szakmai elismerést.

                    2018.óta foglalkozom alapozó mozgásterapeutaként a gyermeki idegrendszer játékos, mozgáson ,- érzékszerveken keresztül történő fejlesztésével.

                    Olyan mozgásfejlesztésen alapuló (ún. motoszenzoros) típusú idegrendszer fejlesztő terápia, mely során a gyermekeknél újra indítjuk az emberi mozgásfejlődés állomásait. Végig vezetjük a gyermekeket az egyszerű mozgásoktól az összetett, sorozatokból álló, bonyolult mozgásokig, a fejlett koordinációt igénylő feladatok megtanulásáig, a jó kivitelezésig. Végig segítjük őket a több irányú figyelmet igénylő feladatok automatizált szintű végrehajtásáig. Fejlesztjük a gyermekek általános mozgásügyességét, a kézügyességet igénylő feladatok kivitelezésének alapjául szolgáló finommotorikát. Elősegítjük a stabil jobb-, vagy baloldali dominancia kialakítását és megerősítését is. Mozgásfejlesztési gyakorlatsorunkkal hatunk a beszéd működéséért (olvasás, írás) felelős területekre is. A terápia során a mozgást kiegészítjük fejlesztő pedagógiai módszerekkel (idő, tér, emlékezet, ritmus, labdagyakorlatok stb.), ezzel tesszük teljessé módszerünk hatását.

                    A foglalkozások közben Figyelek az érés, fejlődés, fejlesztés összefüggéseire és nem utolsó sorban a gyermekek lelkének jóllétére. Igyekszem megtalálni a harmóniát a csoportban, mindezt nagyon játékos formában.
                </>,
            phone: '06/30-3366813',
            image: balajtiBetti,
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the collapsible section
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                Szakemberek
            </Typography>
            <Grid container spacing={4}>
                {experts.map((expert, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            sx={{
                                border: '1px solid #ddd',
                                padding: 2,
                                borderRadius: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                boxShadow: 2,
                                textAlign: 'center',
                                height: openIndex === index ? 'auto' : '300px', // Adjust height based on whether the box is open
                                transition: 'height 0.3s ease', // Smooth transition for height change
                            }}
                        >
                            <Avatar
                                src={expert.image || 'https://via.placeholder.com/120'} // Placeholder if image is not available
                                alt={expert.name}
                                sx={{
                                    width: 120,
                                    height: 120,
                                    marginBottom: 2,
                                }}
                            />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                                {expert.name}
                            </Typography>
                            <IconButton onClick={() => handleToggle(index)} sx={{ marginBottom: 2 }}>
                                {openIndex === index ? <ExpandLess /> : <ExpandMore />} {/* Change the arrow direction */}
                            </IconButton>
                            <Collapse in={openIndex === index}>
                                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                                    {expert.description}
                                </Typography>
                            </Collapse>
                            <Link
                                href={`tel:${expert.phone.replace(/\D/g, '')}`} // Make phone number clickable
                                sx={{
                                    display: 'block',
                                    fontSize: '16px',
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                }}
                            >
                                {expert.phone}
                            </Link>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};



export default SzakemberekPage;

