import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TSMTCsoportosTornaPage = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/foglalkozasok');
    };
    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                <>
                    TSMT<sup>®</sup> Csoportos torna
                </>
            </Typography>
            <Typography variant="body1" paragraph>
                Jelentkezéskor minden esetben szenzomotoros felmérést végzünk, ez alapján beszéljük meg a szülőkkel, hogy szükséges-e mozgásfejlesztő tréning, illetve egyéni vagy csoportos formáját javasoljuk. A csoportos torna abban az esetben hasznos, ha a gyermeknek a figyelem, a feladattudat, a tanulás területén van lemaradása a korosztályához képest, de már önállóan képes a feladatok végrehajtására. Vagyis, képes a fejlesztő szakember adott instrukciókat végighallgatni, a feladatokat megpróbálja teljesíteni. A hatékonyság miatt a csoportokba hasonló jellegű problémával kerülnek gyerekek. A létszám általában 6-8 fő, heti 2 alkalommal, hétköznap délután.
            </Typography>
            <Typography variant="body1" paragraph>
                A feladatok fokozatosan nehezednek és a terhelés is folyamatosan nő.. A mozgásos feladatok segítik az érzékelés, észlelés fejlődését, a testvázlat, a térbeli tájékozódás, a laterialitás, a dominancia beérését. A feladatsorok javítják a gyermek koncentrációs képességeit, megalapozzák, segítik a figyelem jobb irányíthatóságát, a szerialitás, a ritmusérzék és a motoros kreativitás fejlődését. A mondókákkal, énekekkel kísért feladatvégzés során fejlődik kommunikációjuk és anyanyelvi készségeik.      </Typography>
            <Typography variant="body1" paragraph>
                Cél, hogy az iskolai, óvodai beválás sikeresebb legyen, a gyerekek könnyebben alkalmazkodjanak a kihívást jelentő helyzetekben, gyorsabban, ügyesebben, kreatívabban oldják meg a felmerülő problémákat. Éljék át, hogy sikeresek, hogy képesek, hogy a gyakorlással egy nehéz feladat is idővel könnyűvé válik.      </Typography>
            <Button variant="contained" color="primary" onClick={handleBack} sx={{ marginBottom: 2 }}>
                Vissza a Foglalkozásokhoz
            </Button>
        </Box>
    );
};

export default TSMTCsoportosTornaPage;
