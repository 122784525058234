import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import AppMenu from "./AppMenu";
import Page404 from './Page404';
import SzakemberekPage from './SzakemberekPage';
import FoglalkozasokPage from './FoglalkozasokPage';
import TSMTEgyeniTornaPage from './TSMTEgyeniTornaPage';
import TSMTCsoportosTornaPage from './TSMTCsoportosTornaPage';
import BabaTornaPage from './BabaTornaPage';
import TotyogosTornaPage from './TotyogosTornaPage';
import AlapozoMozgasterapiaPage from './AlapozoMozgasterapiaPage';
import DiagnosztikaPage from './DiagnosztikaPage';
import AjanlottSzakemberekPage from './AjanlottSzakemberekPage';
import PricePage from './PricePage';
import KapcsolatPage from './KapcsolatPage';
import { ThemeProvider, CssBaseline, Container } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import GeneralTheme from './GeneralTheme';

const theme = createTheme(GeneralTheme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppMenu />
        <br />
        <Container maxWidth={"lg"}>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/szakemberek" element={<SzakemberekPage />} />
            <Route path="/foglalkozasok" element={<FoglalkozasokPage />} />
            <Route path="/tsmt-egyeni-torna" element={<TSMTEgyeniTornaPage />} />
            <Route path="/tsmt-csoportos-torna" element={<TSMTCsoportosTornaPage />} />
            <Route path="/baba-torna" element={<BabaTornaPage />} />
            <Route path="/totyogos-torna" element={<TotyogosTornaPage />} />
            <Route path="/alapozo-mozgasterapia" element={<AlapozoMozgasterapiaPage />} />
            <Route path="/diagnosztika" element={<DiagnosztikaPage />} />
            <Route path="/ajanlott-szakemberek" element={<AjanlottSzakemberekPage />} />
            <Route path="/arak" element={<PricePage />} />
            <Route path="/kapcsolat" element={<KapcsolatPage />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
