import React from 'react';
import { Typography, Box, List, ListItem, ListItemText, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AlapozoMozgasterapiaPage = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/foglalkozasok');
    };
    return (
        <Box sx={{ padding: 4 }}>           
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                Alapozó Mozgásterápia
            </Typography>
            <Typography variant="body1" paragraph>
                A foglalkozások közben Figyelek az érés, fejlődés, fejlesztés összefüggéseire és nem utolsó sorban a gyermekek lelkének jóllétére. Igyekszem megtalálni a harmóniát a csoportban, mindezt nagyon játékos formában.      </Typography>
            <Typography variant="body1" paragraph>
                A gyerekek heti 2x90 percben, olyan mozgásfejlesztésen alapuló (ún. motoszenzoros) típusú idegrendszer fejlesztő terápián vesznek részt, mely során újra indítjuk az emberi mozgásfejlődés állomásait. Végig vezetjük őket az egyszerű mozgásoktól az összetett, sorozatokból álló, bonyolult mozgásokig, a fejlett koordinációt igénylő feladatok megtanulásáig, a jó kivitelezésig. Végig segítjük, a több irányú figyelmet igénylő feladatok automatizált szintű végrehajtásáig. Fejlesztjük a gyermekek általános mozgásügyességét, a kézügyességet igénylő feladatok kivitelezésének alapjául szolgáló finommotorikát. Elősegítjük a stabil jobb-, vagy baloldali dominancia kialakítását és megerősítését is.
            </Typography>
            <Typography variant="body1" paragraph>
                Mozgásfejlesztési gyakorlatsorunkkal hatunk a beszéd működéséért (olvasás, írás) felelős területekre is. A terápia során a mozgást kiegészítjük fejlesztő pedagógiai módszerekkel (idő, tér, emlékezet, ritmus, labdagyakorlatok stb.), ezzel tesszük teljessé módszerünk hatását.      </Typography>
            <Typography variant="body1" paragraph>
                Tapasztalataink szerint a terápia során javul a gyermekek önértékelése, feladatra összpontosítása és alkalmazkodó képessége is.
            </Typography>
            <Typography variant="body1" paragraph>
                Hatásos:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Megkésett vagy/és hibás, akadályozott beszédfejlődésnél (diszfáziák)," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Kiejtési hibával, pöszeséggel küzdő gyerekeknél," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Diszlexiánál, alaki diszgráfiánál – az olvasás és/vagy az írás alaki részének gyengeségénél," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Azoknál az organikus eredetű túl-, vagy alulmozgásos - figyelemzavaros gyermekeknél, akiknél a szülés körül károsodás keletkezett," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Fejlődésben, érésben elmaradt óvodásoknál," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Enyhe fokú értelmi fogyatékossággal élő gyermekeknél," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Minden olyan gyermeknél, aki mozgásügyetlenségben szenved bármilyen okból," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="vagy szimplán azonak az 5 -12 éves korosztályú gyerekeknek, akik biztos alapokra szeretnének építkezni az iskolában." />
                </ListItem>
            </List>
            <Typography variant="body1" paragraph>
                A fejlesztést alapos vizsgálat előzi meg, amelyről a szülők részletes tájékoztatást kapnak.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleBack} sx={{ marginBottom: 2 }}>
                Vissza a Foglalkozásokhoz
            </Button>
        </Box>
    );
};

export default AlapozoMozgasterapiaPage;
