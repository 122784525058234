import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery, Typography, Box } from '@mui/material';

const data = [
  { foglalkozas: 'Állapot és mozgásvizsgálat, szintfelmérés (mozgás, beszéd)', ara: '15.000Ft' },
  { foglalkozas: 'Longitudinális komplex vizsgálat - 2 éves korig (kb 90 perc)', ara: '20.000Ft' },
  { foglalkozas: 'Longitudinális komplex vizsgálat - 2 éves kor felett (kb 120 perc)', ara: '22.000Ft' },
  { foglalkozas: 'Tornák írása, betanítása, kontroll alkalom', ara: '10.000Ft' },
  { foglalkozas: 'Csoportos mozgásterápiás foglalkozások', ara: '32.000Ft/8 alkalom' },
  { foglalkozas: 'Egyéni mozgásterápiás foglalkozás', ara: '5.000Ft/30 perc' },
];

function PricePage() {
  const isMobile = useMediaQuery('(max-width:600px)'); // Detect if on a mobile device

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant={isMobile ? 'h6' : 'h5'} align="center" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
        Árak
      </Typography>
      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: isMobile ? 300 : 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#FFE0B2' }}>Foglalkozás jellege</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#FFE0B2' }} align="right">
                Ára
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.foglalkozas}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFE0B2', // Lighter peach color on hover
                  },
                }}
              >
                <TableCell component="th" scope="row" sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>
                  {row.foglalkozas}
                </TableCell>
                <TableCell align="right" sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>
                  {row.ara}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PricePage;
