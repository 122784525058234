// theme.js
import { createTheme } from '@mui/material/styles';
import bgImage from './assets/bg.jpg'; // Background image import

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffa97b",
    },
    secondary: {
      main: "#ffe89c",
    },
  },
  typography: {
    h4: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  // Global background style
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${bgImage})`, // Use the imported background image
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed', // Optional: makes the background fixed while scrolling
          height: '100vh',
          margin: 0,
          padding: 0,
        },
      },
    },
  },
});

export default theme;
