import React from 'react';
import { Typography, Box } from '@mui/material';

const TotyogosTornaPage = () => {
    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                Totyogós Torna
            </Typography>
            <Typography variant="body1" paragraph>
                Kiscsoportos óra. 6 hó - 1,5 éves korban ajánlott, édesanyával együtt végzett torna, rengeteg ölbéli játékkal.
            </Typography>
            <Typography variant="body1" paragraph>
                Ebben a korban a gyerekek igazi felfedezők, minden IS érdekli őket. A torna alkalmával rengeteg énekkel, mondókával és tornaeszközzel fognak megismerkedni. A szülők is rengeteg új ötletet kapnak, örökmozgó babájuk "lekötésére". A torna során rengeteg olyan "feladatot" csinálunk, aminek hatására nem csak a mozgásfejlődésben, de az idegrendszer érésében, a finommotorikában és a beszédfejlődésben is eredményeket fogunk elérni, na meg persze hangos kacagásokat! :)            </Typography>
            <Typography variant="body1" paragraph>
                Célja: mozgásfejlesztés, finommotorika, idegrendszeri fejlődés.
            </Typography>
        </Box>
    );
};

export default TotyogosTornaPage;
