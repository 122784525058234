import React, { useState } from "react";
import { AppBar, Toolbar, Box, IconButton, MenuItem, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "./assets/logo.png";
import { useNavigate } from "react-router-dom";

const Menu = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setOpenDrawer(!openDrawer); // This only updates the state for the drawer
    };

    const handleNavigation = (path) => {
        navigate(path); // This function handles the navigation without any state update causing a loop
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "#ffa97b" }}>
            <Box
                sx={{
                    backgroundColor: "#ffa97b",
                    padding: "5px 10px",
                    textAlign: "right",
                    color: "#000",
                    fontSize: "14px",
                }}
            >
                <a href="tel:+36203111441" style={{ color: "#000", textDecoration: "none" }}>
                    +36203111441
                </a>
                {" : "}
                <a href="mailto:orosziren2@gmail.com" style={{ color: "#000", textDecoration: "none" }}>
                    orosziren2@gmail.com
                </a>
            </Box>

            <Toolbar sx={{ backgroundColor: "#ffe89c", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={logo}
                        onClick={() => handleNavigation("/")} // Use the handleNavigation function for homepage navigation
                        alt="Kockakuckó Logo"
                        style={{ height: "50px", marginRight: "10px", cursor: 'pointer' }} // Add cursor pointer to indicate clickable
                    />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ color: "#000", fontWeight: "bold", fontSize: "24px" }}
                    >
                        {/* Your logo title text can go here */}
                    </Typography>
                </Box>

                {/* Conditional rendering for mobile and desktop */}
                {isMobile ? (
                    // Mobile Menu (Drawer)
                    <>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleDrawerToggle}
                            sx={{ display: { xs: 'block', sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={openDrawer}
                            onClose={handleDrawerToggle}
                        >
                            <List sx={{ width: 250 }} role="presentation">
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleNavigation("/");
                                        setOpenDrawer(false); // Close the drawer
                                    }}
                                >
                                    <ListItemText primary="Hírek" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleNavigation("/szakemberek");
                                        setOpenDrawer(false); // Close the drawer
                                    }}
                                >
                                    <ListItemText primary="Szakemberek" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleNavigation("/foglalkozasok");
                                        setOpenDrawer(false); // Close the drawer
                                    }}
                                >
                                    <ListItemText primary="Foglalkozások" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleNavigation("/diagnosztika");
                                        setOpenDrawer(false); // Close the drawer
                                    }}
                                >
                                    <ListItemText primary="Diagnosztikai eljárások" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleNavigation("/ajanlott-szakemberek");
                                        setOpenDrawer(false); // Close the drawer
                                    }}
                                >
                                    <ListItemText primary="Ajánlott szakemberek" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleNavigation("/arak");
                                        setOpenDrawer(false); // Close the drawer
                                    }}
                                >
                                    <ListItemText primary="Árak" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        handleNavigation("/kapcsolat");
                                        setOpenDrawer(false); // Close the drawer
                                    }}
                                >
                                    <ListItemText primary="Kapcsolat" />
                                </ListItem>
                            </List>
                        </Drawer>
                    </>
                ) : (
                    // Desktop Menu (Horizontal)
                    <Box sx={{ display: "flex", gap: "20px" }}>
                        <MenuItem onClick={() => handleNavigation("/")} sx={{ color: "#000", fontWeight: "bold" }}>
                            Hírek
                        </MenuItem>
                        <MenuItem onClick={() => handleNavigation("/szakemberek")} sx={{ color: "#000", fontWeight: "bold" }}>
                            Szakemberek
                        </MenuItem>
                        <MenuItem onClick={() => handleNavigation("/foglalkozasok")} sx={{ color: "#000", fontWeight: "bold" }}>
                            Foglalkozások
                        </MenuItem>
                        <MenuItem onClick={() => handleNavigation("/diagnosztika")} sx={{ color: "#000", fontWeight: "bold" }}>
                            Diagnosztikai eljárások
                        </MenuItem>
                        <MenuItem onClick={() => handleNavigation("/ajanlott-szakemberek")} sx={{ color: "#000", fontWeight: "bold" }}>
                            Ajánlott szakemberek
                        </MenuItem>
                        <MenuItem onClick={() => handleNavigation("/arak")} sx={{ color: "#000", fontWeight: "bold" }}>
                            Árak
                        </MenuItem>
                        <MenuItem onClick={() => handleNavigation("/kapcsolat")} sx={{ color: "#000", fontWeight: "bold" }}>
                            Kapcsolat
                        </MenuItem>
                    </Box>
                )}

                {/* Facebook Icon */}
                <IconButton
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#000" }}
                >
                    <FacebookIcon sx={{ fontSize: "24px" }} />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Menu;
