import React from 'react';
import { Box, Grid, Typography, Card, CardContent, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const FoglalkozasokPage = () => {
    const activities = [
        {
            title: (
                <>
                    TSMT<sup>®</sup> Egyéni torna
                </>
            ),
            titleText: 'TSMT® Egyéni torna',
            description:
                'A vizsgálatokon látható, illetve a gyermek viselkedésében, tüneteiben tapasztalható problémák határozzák meg a gyermek egyéni terápiájának céljait, illetve jelölik ki az ehhez használható mozgásos feladatokat...',
            goal: 'A célja: idegrendszer érési folyamatainak beindítása, a zavaró tünetek csökkentése, és egyéb fejlesztési célok.',
            link: '/tsmt-egyeni-torna',
        },
        {
            title: (
                <>
                    TSMT<sup>®</sup> Csoportos torna
                </>
            ),
            titleText: 'TSMT® Csoportos torna',
            description:
                'Jelentkezéskor minden esetben szenzomotoros felmérést végzünk, és a csoportos torna abban az esetben hasznos, ha a gyermeknek a figyelem, a feladattudat, a tanulás területén van lemaradása...',
            goal: 'Célja: iskolai, óvodai beválás, jobb alkalmazkodás, gyorsabb problémamegoldás.',
            link: '/tsmt-csoportos-torna',
        },
        // {
        //   title: 'Baba torna',
        //   description:
        //     'Anya és babája ezeken az órákon teljesen összehangolódnak, sok szeretgetés, érintés, masszírozás...',
        //   goal: 'Célja: stabil alapok a mozgásfejlődéshez és a beszédfejlődéshez.',
        //   link: '/baba-torna',
        // },
        // {
        //   title: 'Totyogós torna',
        //   description:
        //     '6 hónapos - 1,5 éves korú gyerekeknek ajánlott, édesanyával együtt végzett torna...',
        //   goal: 'Célja: mozgásfejlesztés, finommotorika, idegrendszeri fejlődés.',
        //   link: '/totyogos-torna',
        // },
        {
            title: 'Alapozó mozgásterápia',
            titleText: 'Alapozó mozgásterápia',
            description:
                'A gyermekek heti 2x90 percben olyan mozgásfejlesztésen alapuló terápián vesznek részt...',
            goal: 'Célja: mozgásfejlesztés, koordináció, beszéd működésének fejlesztése.',
            link: '/alapozo-mozgasterapia',
        },
    ];

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                Foglalkozások
            </Typography>

            <Grid container spacing={4}>
                {activities.map((activity, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ borderRadius: 2, boxShadow: 3, height: '100%' }}>
                            <CardContent>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                    {activity.title}
                                </Typography>
                                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                                    {activity.description}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                    {activity.goal}
                                </Typography>
                                <Link
                                    component={RouterLink}
                                    to={activity.link}
                                    sx={{
                                        display: 'inline-block',
                                        fontSize: '16px',
                                        color: 'primary.main',
                                        textDecoration: 'none',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {`Tovább a ${activity.titleText}hoz`}
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FoglalkozasokPage;

