
import React, { useState } from 'react';
import { Box, Grid, Typography, Avatar, Link, IconButton, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess  } from '@mui/icons-material'; // Arrow icon for collapse functionality
import rajnaiDori from './assets/rajnaiDori.png'; // Add image for Orosz Irén

const AjanlottSzakemberekPage = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const experts = [
        {
            name: 'Rigó Noémi - MOMEJ',
            description: <>2014-ben szereztem meg a diplomámat az ELTE-TÓK tanító szakán, német műveltség területen. Ezt követően öt évet Frankfurtban éltem. A megszerzett tudásomat tovább kamatoztatva részt vettem a Lingua Hungarica Egyesület óvodai és iskolai foglalkozásain, ahol legfőbb célunk az anyanyelvünk művelése, hagyományaink átadása volt a kint élő magyar gyermekek számara. A külföldön töltött évek soràn betekintést nyertem a németországi kisgyermek nevelésbe, képzéseken, előadásokon vehettem részt. Értékes tapasztalatokkal gazdagodtam a különböző korosztályú gyermekekkel való munkáim során.

            Tanulmányaimat folytatva 2018-ban részt vettem a Komplex Mozgásterápia képzésen, melynek célja a tanulási nehézségek megelőzése és oldása. 2019-ben tettem le a szakvizsgámat, mint fejlesztőpedagógus. A kétéves képzés alatt lehetőségem nyílt megtanulni, hogyan kell egyéni vizsgálatokat végezni, egyéni fejlesztésiterveket elkészíteni szakvélemény vagy saját vizsgálatokat alapján, és hogyan nyújthatunk a gyermekek számára hatékony komplex egyéni- vagy csoportos fejlesztést. 2020-ban megismerkedtem a 3labdás zsonglőrtechnika megelőző és terápiás hatásával, mely ajánlott a tanulási-, figyelem-, kontrollzavar és hiperaktivitás esetén. 2021-ben elsajátítottam a Mozgáskotta módszerét, elvégeztem a Dyscalculine Programot, mellyel a matematikai nehézségekkel küzdő gyermekeknek tudok hatékony segítséget nyújtani. Részt vettem a GMP-GOH beszédpercepciós és diagnosztika és terápia képzésen, mely vizsgálat segít feltárni a beszédészlelési és beszédértési elmaradásokat, zavarokat. Valamint a Játékos Agyintegráció csoportvezetői és az Aktív Poharak a fejlesztésben módszertanával bővült a tudásom.
            
            Az elvégzett képzésekben egy közös biztos van: mindegyik játékos és (siker)élmény alapon közelíti meg a gyermekek képességeinek megsegítését, fejlesztését, motiválását. Mely nagyban meghatározza a hozzáállásukat a problémákhoz, feladatokhoz, tanuláshoz, ezáltal az önértékelésükhez.
            
            Az egyik legfontosabb tényezőnek tartom a tanítás-tanulás folyamatában az élményszerzést, mely nemcsak motiválóan hat a gyermekekre, hanem nagyban meghatározza a hosszútávú hozzáállásukat a tanuláshoz. Ebben a folyamatban kiemelten fontos az iskolába lépést követő első időszak. Hiszem, hogy ha az óvodás gyermek élményekkel kezdheti el az iskolát, sikerei lesznek. Ehhez azonban szükséges a jól megalapozott részképességek és az érzelmi stabilitás. Ezeket a képességeket az Iskolaelőkészítő foglalkozások alatt MEsékkel, MOzgással és sok-sok Játékkal támogatjuk meg a MEMOJ-fejlesztőkuckóban. Így a gyermekeket biztonsággal indíthatjuk el a tanulás útján, hogy további sikerekben és élményekben lehessen részük a tanulmányaik során.

            </>,
            phone: '+06/70-8813797',
            image: null,
        },
        {
            name: 'Rajnai-Gyopár Dóra - Kismamatorna, szétnyílt hasizom torna',
            description: <>A Semmelweis Egyetem Egészségtudományi Karán végeztem gyógytornász-fizioterapeutaként 2016-ban, majd a Szent Margit Kórház Fejlődésneurológia osztályán helyezkedtem el. Itt több mint egy évig dolgoztam idegrendszeri sérült újszülött- és koraszülött csecsemőkkel.
            2017. novemberétől erősítem az Azur Gyógytorna csapatát, ahol főleg gyermek mozgásfejlesztéssel, illetve a kismamákat és édesanyákat érintő mozgásterápiával- rehabilitációval (intimtorna, kismama torna, szétnyílt hasizom kezelése) foglalkozom.
            
            Továbbképzéseim:
            Kinesiotape tanfolyam
            EBM- a Medencefenék fizioterápiája
            Inkontinencia és kezelési lehetőségei- elméleti továbbképzés
            Fit-ball for Pregnant
            Mummy Tummy Training (Szétnyílt hasizom terápiája)
            Manuális Neurodinamika és Fasciaorganizáció
            Mozgásos Testelemzés A, B, C, D
            Modern Scoliosis Terápia Schroth szerint</>,
            phone: '06/30-6405683',
            image: rajnaiDori,
        },
        {
            name: 'Nagy Klaudia - Pszichológus',
            description: (
                <>
                    Nagy Klaudia vagyok, tanácsadó szakpszichológus. Munkám során fontosnak tartom a társas környezet szerepét, a komplex nézőpont alkalmazását és a módszertani változatosságot. Közös célunk, hogy a kliens megtanulja, hogyan tudja a számára legmegfelelőbb megoldást megtalálni és azt a hétköznapokban alkalmazni.

Milyen problémával fordulhat hozzám?

Önbizalom növelése, önismeret mélyítése
Életvezetési nehézségek
Szorongásos problémák, stresszkezelés
Kommunikáció és megküzdési stratégiák fejlesztése
Beilleszkedési és magatartási problémák
Szülői konzultáció
Pályaválasztási és karrierváltási kérdések
Várom jelentkezését online vagy személyes pszichológiai tanácsadásra és konzultációra a +36/70-770-5102-es telefonszámon vagy a nagyklaudia.pszi@gmail.com email címen. </>
            ),
            phone: '06/70-7705102',
            image: null,
        }
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the collapsible section
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                Ajánlott Szakemberek
            </Typography>
            <Grid container spacing={4}>
                {experts.map((expert, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            sx={{
                                border: '1px solid #ddd',
                                padding: 2,
                                borderRadius: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                boxShadow: 2,
                                textAlign: 'center',
                                height: openIndex === index ? 'auto' : '400px', // Adjust height based on whether the box is open
                                transition: 'height 0.3s ease', // Smooth transition for height change
                            }}
                        >
                            <Avatar
                                src={expert.image || 'https://via.placeholder.com/120'} // Placeholder if image is not available
                                alt={expert.name}
                                sx={{
                                    width: 120,
                                    height: 120,
                                    marginBottom: 2,
                                }}
                            />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                                {expert.name}
                            </Typography>
                            <IconButton onClick={() => handleToggle(index)} sx={{ marginBottom: 2 }}>
                                {openIndex === index ? <ExpandLess /> : <ExpandMore />} {/* Change the arrow direction */}
                            </IconButton>
                            <Collapse in={openIndex === index}>
                                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                                    {expert.description}
                                </Typography>
                            </Collapse>
                            <Link
                                href={`tel:${expert.phone.replace(/\D/g, '')}`} // Make phone number clickable
                                sx={{
                                    display: 'block',
                                    fontSize: '16px',
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                }}
                            >
                                {expert.phone}
                            </Link>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};



export default AjanlottSzakemberekPage;

