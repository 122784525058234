import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  useMediaQuery,
  CssBaseline,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import girlWithBallImage from './assets/girlWithBall.png';

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
  },
});

const HomePage = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if screen size is mobile
  const [openCards, setOpenCards] = useState({}); // Manage open/close state for each card

  const toggleCard = (ageGroup) => {
    setOpenCards((prev) => ({ ...prev, [ageGroup]: !prev[ageGroup] }));
  };

  const renderCard = (ageGroup, categories) => {
    const isOpen = openCards[ageGroup];

    return (
      <Card sx={{ marginBottom: '20px', boxShadow: 3 }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer'
            }}
            onClick={() => toggleCard(ageGroup)}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', color: '#333' }}
            >
              {ageGroup}
            </Typography>
            <IconButton>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>

          {isOpen && (
            <Box sx={{ marginTop: '15px' }}>
              {categories.map(({ category, symptoms }) => (
                <Box key={category} sx={{ marginBottom: '15px' }}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: '10px', fontWeight: 'bold', color: '#555' }}
                  >
                    {category}
                  </Typography>
                  {symptoms.map((symptom, index) => (
                    <Typography key={index} variant="body1" sx={{ marginBottom: '8px', color: '#777' }}>
                      - {symptom}
                    </Typography>
                  ))}
                </Box>
              ))}
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Apply global styles */}
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: '20px',
            textAlign: 'center',
            color: '#000',
            fontWeight: 'bold',
          }}
        >
          Mire érdemes figyelni?
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '20px'
          }}
        >
          <Box sx={{ flex: 1 }}>
            {/* 3 months to 3 years */}
            {renderCard("3 hónapos kortól 3 éves korig", [
              {
                category: "Mozgással kapcsolatos tünetek",
                symptoms: [
                  "eltérő izomtónus",
                  "szopási, étkezési nehézség",
                  "megkésett, vagy eltérő mozgásfejlődés, aszimmetria",
                  "egyensúlyi gyengeség",
                  "finommotorika késése, éretlensége",
                  "beszédfejlődés lassulása, megakadása",
                  "primitív reflexek tartós fennmaradása",
                ],
              },
              {
                category: "Gondolkodással kapcsolatos tünetek",
                symptoms: [
                  "megkésett beszédfejlődés, beszédértési eltérések, kommunikációs nehézségek",
                  "alvászavar",
                  "figyelem gyengesége, rövidsége",
                  "észlelési funkciók gyengesége",
                  "szem-kéz koordináció hiánya",
                  "szerepjáték megjelenésének hiánya",
                  "ok-okozati gondolkodás megjelenésének hiánya",
                ],
              },
              {
                category: "Szociális tünetek",
                symptoms: [
                  "nehéz megnyugtathatóság",
                  "kötődési zavarok",
                  "gyenge együttműködés, nehéz irányíthatóság, feladattudat nehézsége",
                  "érzelmek megosztásának, megértésének hiánya",
                  "tiltás meg nem értése",
                  "öntörvényű, akaratos viselkedés",
                  "agresszió",
                  "önagresszió",
                  "dührohamok",
                ],
              },
            ])}

            {/* 3 years to 6 years */}
            {renderCard("3 éves kortól 6 éves korig", [
              {
                category: "Mozgással kapcsolatos tünetek",
                symptoms: [
                  "gyenge mozgáskoordináció",
                  "új mozgások tanulása nehézkes, lassú, pl.: biciklizés, hinta hajtása",
                  "grafomotorika gyengesége, nem rajzolás",
                  "nehézkes önkontroll",
                  "hanyag tartás",
                ],
              },
              {
                category: "Gondolkodással kapcsolatos tünetek",
                symptoms: [
                  "sikertelen szobatisztaság",
                  "figyelemzavar",
                  "információ feldolgozási zavar",
                  "emlékezeti gyengeség",
                  "tevékenységek megszervezésének gyengesége",
                  "testvázlat, téri tájékozódás gyengesége",
                  "belátáson, tapasztalaton álló gondolkodás megjelenésének késése",
                  "diszlexia, diszkalkulia veszélyezettség",
                  "szorongás, depresszió, fóbiák",
                ],
              },
              {
                category: "Szociális tünetek",
                symptoms: [
                  "nehezített beilleszkedés",
                  "nehéz szabályfelismerés, szabálykövetés",
                  "kötődési zavar",
                  "segítségadás elutasítása",
                  "feladathelyzetekből való kiszállás",
                  "kontrollfunkció gyengesége",
                  "szorongás",
                  "depresszió",
                  "autizmus spektrum zavar",
                ],
              },
            ])}

            {/* 6 years to 11 years */}
            {renderCard("6 éves kortól 11 éves korig", [
              {
                category: "Mozgással kapcsolatos tünetek",
                symptoms: [
                  "sportolási alaptechnikák elsajátítása nehézkes",
                  "lordosis, kiphosis",
                ],
              },
              {
                category: "Gondolkodással kapcsolatos tünetek",
                symptoms: [
                  "tanulási zavar",
                  "figyelemzavar",
                  "lassú tanulási tempó",
                ],
              },
              {
                category: "Szociális tünetek",
                symptoms: [
                  "áldozat, bohóc, agresszor szerep",
                  "pszichoszomatikus tünetek megjelenése",
                  "időbeosztás nehézsége",
                  "játszmák",
                ],
              },
            ])}
          </Box>

          {/* Image Section */}

        </Box>
        <Box sx={{ flex: 1 }}>
          <img
            src={girlWithBallImage}
            alt="Girl with Ball"
            style={{
              width: '40%', // Adjust the width
              maxHeight: '40%', // Set max height to avoid overflow
              objectFit: 'cover', // Maintain aspect ratio while covering
              borderRadius: '8px',
              margin: '0 auto', // Center the image
              display: 'block', // Center horizontally
            }}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default HomePage;


