import React from 'react';
import { Typography, Box, Button, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TSMTEgyeniTornaPage = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/foglalkozasok');
    };
    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                <>
                    TSMT<sup>®</sup> Egyéni torna
                </>
            </Typography>
            <Typography variant="body1" paragraph>
                A vizsgálatokon látható, illetve a gyermek viselkedésében, tüneteiben tapasztalható problémák határozzák meg a gyermek egyéni terápiájának céljait, illetve jelölik ki az ehhez használható mozgásos feladatokat. A terapeuta összeállítja a gyermek egyéni feladatait, amelyet írásban, és rajzban is rögzít és a szülőnek átad. Az első találkozó alkalmával a terapeuta bemutatja a feladatokat, azok helyes kivitelezését, és elmondja az adott feladat céljait. A szülők a terapeuta által meghatározott heti ismétlésszámban otthon tornáznak a gyermekkel. Előre megbeszélt időpontban történik mindig az összeállított feladatsor kontrollja, amikor az eredeti tornasorban javítás, módosítás, nehezítés, új feladatok hozzátétele történik. A feladatsorok folyamatosan nehezednek, bonyolultabbá válnak. A nehezítések, változtatások üteme, fokozatai is egyénre szabottak.      </Typography>
            <Typography variant="body1" paragraph>
                Célja:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="az idegrendszer érési folyamatainak beindítása," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="az együttműködés kialakítása," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="a zavaró tünetek csökkentése," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="az adott életkorban elvárható motoros, pszichés-kognitív és szociális teljesítmények elérése," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="a csecsemőkori/primitív reflexprofil integrálása," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="a beszéd és a mozgás szinkronjának kialakításal," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="felzárkóztatás," />
                </ListItem>
                <ListItem>
                    <ListItemText primary="csoport érettség elérése" />
                </ListItem>
            </List>
            <Button variant="contained" color="primary" onClick={handleBack} sx={{ marginBottom: 2 }}>
                Vissza a Foglalkozásokhoz
            </Button>
        </Box>
    );
};

export default TSMTEgyeniTornaPage;
