import React from 'react';
import { Typography, Box } from '@mui/material';

const BabaTornaPage = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
        Baba Torna
      </Typography>
      <Typography variant="body1" paragraph>
      Ugye, milyen jól esik kicsit kimozdulni, találkozni, tapasztalatot cserélni más édesanyákkal? Ráadáasul ezt egy olyan foglalkozás keretében, ami maximálisan rólad és a kisbabádról szól. Anya és babája ezeken az órákon teljesen összehangolódnak, sok szeretgetés, érintés, masszírozás. Dalok és mondókák kísérnek minden játékos fejlesztő mozdulatot. Megtanulhatod, miket tudtok otthon játszani, ami fejlesztőleg hat kisbabádra. A foglalkozásokon stabil alapokat kaphattok az időben történő mozgásfejlődéshez, az idegrendszer megfelelő éréséhez, a beszédfejlődés kialakulásához. Az órák az első mosolyok és kacajok színtere szokott lenni. :)      </Typography>
      <Typography variant="body1" paragraph>
        Célja: stabil alapok a mozgásfejlődéshez és a beszédfejlesztéshez.
      </Typography>
    </Box>
  );
};

export default BabaTornaPage;
